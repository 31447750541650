<template>
  <v-col
      :cols="6"
      :xs="12"
      :sm="12"
      :md="6"
      :lg="6"
      :xl="$vuetify.breakpoint.width >= 2800 ? 2 : $vuetify.breakpoint.width >= 2000 ? 3 :  4"
>
    <v-card id="card-trip" class="pa-0 elevation-4">
      <v-card-title class="trip-title pl-4 py-1 pa-0">
        <v-row>
          <v-col cols="8">
            <h4 class="primary--text ml-2 text-title-card" id="title-card">
              {{
                $t("components.views.trip.offers.client.card_driver.trip_no")
              }}
              {{ dataProp.information.tripNumber }}
            </h4>
          </v-col>
          <v-col cols="4" align="right">
            <h4 class="green--text text--darken-2 moneytext">
              {{
                formatMoney(dataProp.status.amount, dataProp.status.currency)
              }}
            </h4>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider  class="mb-1 custom-divider" />
        <v-card-text class="pl-0 py-2 pr-0">
          <v-container class="pa-0 ma-0">
            <v-row class="justify-center card-height " >

              <!-- Columna 1: Route trip -->
              <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="py-1 pl-0 card-route">
                <v-timeline align-top dense  class="pa-0 ">
                  <v-timeline-item  color="secondary"  class="ma-0 pt-3" small>
                    <v-row>
                      <v-col class="stop-item ">
                        <v-chip color="secondary" variant="flat" class="mb-2 card-chip">
                          <p class="ma-0 pa-0 chip-text">
                            {{
                              $t(
                                "components.views.trip.offers.transport_company.card_transport_company.origin"
                              )
                            }}
                          </p>
                        </v-chip>
                        <div class="mb-1">
                          <b>
                            <v-icon class="primary--text mr-2" size="small">
                              fa-solid fa-map-marker-alt
                            </v-icon>
                            {{$t("components.views.trip.offers.transport_company.card_transport_company.city")}}:</b>
                          <span class="route-text">
                            {{ originCity }}
                          </span>
                        </div>
                        <div>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <p id="address" class="mb-1 text" v-bind="attrs" v-on="on">
                              <b>
                                <v-icon class="primary--text mr-2" size="small">
                                  fa-solid fa-map-marked-alt
                                </v-icon>
                                {{$t("components.views.trip.offers.transport_company.card_transport_company.address")}}:</b>
                              <span class="route-text">
                                {{ originAddress }}
                              </span>
                            </p>
                          </template>
                          <span>{{ dataProp.information.origin.address }}</span>
                        </v-tooltip>
                      </div>
                      <div>
                        <b>
                          <v-icon class="primary--text mr-2" size="small">
                            fa-solid fa-calendar-alt
                          </v-icon>
                          {{ $t("components.views.trip.offers.transport_company.card_transport_company.date" ) }}:</b>
                        <span class="route-text">
                          {{ dataProp.information.origin.schedule_date }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
                <v-timeline-item color="secondary" small>
                  <v-row class="pt-1">
                    <v-col class="stop-item">
                      <v-chip color="secondary" variant="flat" class="mb-2 card-chip">
                        <p class="ma-0 pa-0 chip-text">
                          {{$t("components.views.trip.offers.transport_company.card_transport_company.destiny")}}
                        </p>
                      </v-chip>
                      <div class="mb-1">
                        <b>
                          <v-icon class="primary--text mr-2" size="small">
                            fa-solid fa-map-marker-alt
                          </v-icon>
                          {{ $t("components.views.trip.offers.transport_company.card_transport_company.city")}}:</b>
                        <span class="route-text">
                          {{ destinyCity }}
                        </span>
                      </div>
                      <div>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <p id="address" class="mb-1 text" v-bind="attrs" v-on="on">
                              <b>
                                <v-icon class="primary--text mr-2" size="small">
                                  fa-solid fa-map-marked-alt
                                </v-icon>
                                {{ $t("components.views.trip.offers.client.card_driver.address")}}:</b>
                              <span class="route-text">
                                {{ destinyAddress }}
                              </span>
                            </p>
                          </template>
                          <span>{{ dataProp.information.destiny.address }}</span>
                        </v-tooltip>
                      </div>
                      <div>
                        <b>
                          <v-icon class="primary--text mr-2" size="small">
                            fa-solid fa-calendar-alt
                          </v-icon>
                          {{ $t("components.views.trip.offers.transport_company.card_transport_company.date")}}:</b>
                        <span class="route-text">
                          {{ dataProp.information.destiny.schedule_date }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
                </v-timeline>
                </v-col>
                <!-- columna 2: info trip -->
                <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="mb-1  info-offers-container">
                  <v-row class="justify-center py-0 mt-0 py-2 card-info-offers">
                    <v-col cols="12" class="py-0 mt-0" >
                      <p class="text"  v-if="dataProp.status.type == 8">
                        <b
                          >{{$t("components.views.trip.offers.transport_company.card_transport_company.status" )}}:
                        </b></br>
                        <span class="route-text">
                          {{ $t("components.views.trip.offers.transport_company.card_transport_company.under_negotiation" ) }}
                        </span>
                      </p>
                      <!-- item -->
                      <p class="text" >
                        <b
                          >{{ $t("components.views.trip.offers.transport_company.card_transport_company.type_operation")}}:
                        </b></br>
                        <span class="route-text">
                          {{ dataProp.information.nameTypeOperation }}
                        </span>
                      </p>
                      <!-- item -->
                      <p class="text">
                        <b
                          >{{
                            $t(
                              "components.views.trip.offers.transport_company.card_transport_company.date_publish"
                            )
                          }}:
                        </b> </br>
                        <span class="route-text">
                          {{
                          moment
                            .utc(dataProp.information.createdOn, "YYYY/MM/DD H:mm")
                            .local()
                            .format("DD/MM/YYYY HH:mm")
                          }}
                        </span>
                      </p>
                      <!-- item -->
                      <p class="my-0 py-0 text">
                        <b
                          >{{
                            $t(
                              "components.views.trip.offers.transport_company.card_transport_company.time_publish"
                            )
                          }}:
                        </b> </br>
                        <span class="route-text">
                          {{ timeElpased(dataProp.information.createdOn) }}
                        </span>
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
          </v-container>
        </v-card-text>
      <v-divider  class="custom-divider" />
      <v-card-actions class="card-btn-actions pa-0 ma-0">
        <v-container class="ma-0 pa-0">
          <v-row class="justify-center ma-0 pa-0 btn-container">
            <div class="btn-details">
              <v-btn
                variant="outlined"
                color="blue-darken-4"
                class="card-btn"
                @click="onOpenInfoOffer()"
              >
                <v-icon class="mr-3" small>fa-solid fa-circle-info</v-icon>
                {{
                  $t(
                    "components.views.trip.offers.transport_company.card_transport_company.detail"
                  )
                }}
              </v-btn>
            </div>
            <div class="btn-cancel">
              <v-btn
                variant="outlined"
                color="blue-darken-4"
                class="card-btn "
                @click="onCancelFormOffer()"
                :disabled="dataProp.status.type === 8"
              >
                <v-icon class="mr-3" size="16"> fa-solid fa-times</v-icon>
                {{
                  $t(
                    "components.views.trip.offers.transport_company.card_transport_company.reject"
                  )
                }}
              </v-btn>
            </div>
            <div class="btn-accept">
              <v-btn
                class="px-13 card-btn"
                color="secondary"
                @click="onOpenModalFormOffer()"
                :disabled="dataProp.status.type === 8"
              >
                <v-icon class="mr-2" size="16"> fa-solid fa-check</v-icon>
                {{
                  $t(
                    "components.views.trip.offers.transport_company.card_transport_company.accept"
                  )
                }}
              </v-btn>
            </div>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialogFormOffer" width="600px" scrollable persistent>
      <ModalFormAccept
        @closeForm="onCloseModalFormOffer"
        @reloadPage="reloadPage"
        :idMonitoringOrder="dataProp.idMonitoringOrder"
        :key="index"
        :value="dataProp.status.amount"
        :idCompanyTransporter="dataProp.information.idCompany"
      />
    </v-dialog>

    <v-dialog
      v-model="dialogFormCounterOffer"
      width="600px"
      scrollable
      persistent
    >
      <ModalFormCounterOffer
        @closeForm="onCloseModalFormOffer"
        @reloadPage="reloadPage"
        :idMonitoringOrder="dataProp.idMonitoringOrder"
        :value="dataProp.status.amount"
        :idCompanyTransporter="dataProp.information.idCompany"
        :key="index"
      />
    </v-dialog>

    <v-dialog v-model="dialogInfoOffer" width="900px" persistent>
      <ModalDetails
        @closeForm="onCloseInfoOffer"
        :idMonitoringOrder="dataProp.idMonitoringOrder"
        :type="dataProp.type"
        :key="index"
      />
    </v-dialog>
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ModalFormAccept: () =>
      import(
        /* webpackChunkName: "ModalFormAccept" */ "@/components/views/Trip/Offers/TransportCompany/Modals/ModalFormAccept/ModalFormAccept.vue"
      ),
    ModalFormCounterOffer: () =>
      import(
        /* webpackChunkName: "ModalFormCounterOffer" */ "@/components/views/Trip/Offers/TransportCompany/Modals/ModalFormCounterOffer/ModalFormCounterOffer.vue"
      ),
    ModalDetails: () =>
      import(
        /* webpackChunkName: "ModalDetails" */ "@/components/views/Trip/Offers/TransportCompany/Modals/ModalDetails/ModalDetails.vue"
      ),
  },
  data() {
    return {
      dialogInfoOffer: false,
      dialogFormOffer: false,
      dialogFormCounterOffer: false,
      index: 0,
    };
  },
  computed: {
    dataProp: {
      get() {
        return this.data;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    ...mapGetters({
      user: "auth/user",
    }),
    title() {
      let val = this.$vuetify.breakpoint.lg
          ? 20
          : this.$vuetify.breakpoint.xl
          ? 20
          : 10,
        title = this.dataProp.information.tripNumber;
      return title.length > val ? title.substr(0, val) + " …" : title;
    },
    destinyCity() {
      return this.dataProp.information.destiny.city == null
        ? ""
        : this.dataProp.information.destiny.city;
    },
    originCity() {
      return this.dataProp.information.origin.city == null
        ? ""
        : this.dataProp.information.origin.city;
    },
    destinyAddress() {
      let val = this.$vuetify.breakpoint.lg
          ? 40
          : this.$vuetify.breakpoint.xl
          ? 50
          : 40,
        address = this.dataProp.information.destiny.address;
      return address.length > val ? address.substr(0, val) + " …" : address;
    },
    originAddress() {
      let val = this.$vuetify.breakpoint.lg
          ? 40
          : this.$vuetify.breakpoint.xl
          ? 50
          : 40,
        address = this.dataProp.information.origin.address;
      return address.length > val ? address.substr(0, val) + " …" : address;
    },
  },
  methods: {
    ...mapActions("loader", ["showLoader"]),
    ...mapActions("snackbar", ["showSnack"]),

    async onOpenModalFormOffer() {
      if (
        await this.$root.$confirm(
          this.$t(
            "components.views.trip.offers.transport_company.card_transport_company.title_counteroffer"
          ),
          this.$t(
            "components.views.trip.offers.transport_company.card_transport_company.description_counteroffer"
          ),
          "si"
        )
      ) {
        this.dialogFormCounterOffer = true;
      } else {
        this.dialogFormOffer = true;
      }
      this.index += 1;
    },

    async onCancelFormOffer() {
      if (
        await this.$root.$confirm(
          this.$t(
            "components.views.trip.offers.transport_company.card_transport_company.reject_title"
          ),
          this.$t(
            "components.views.trip.offers.transport_company.card_transport_company.reject_description"
          ),
          "Si, Rechazar"
        )
      ) {
        this.showLoader({
          show: true,
        });

        let userMap = JSON.parse(this.user);

        let params = {
          idMonitoringOrder: this.dataProp.idMonitoringOrder,
          idTransporter: userMap.user.idCompany,
        };

        await this.axios
          .post("Offers/RejectTransportCompany", params)
          .then(() => {
            this.showSnack({
              text: this.$t(
                "components.views.trip.offers.transport_company.card_transport_company.success_reject"
              ),
              title: this.$t(
                "components.views.trip.offers.transport_company.card_transport_company.success"
              ),
              name: "success",
            });
          })
          .catch((error) => {
            this.showSnack({
              text: error.response.data,
              title: "Error",
              name: "error",
            });
          })
          .finally(() => {
            this.showLoader({
              show: false,
            });
            this.$emit("reloadPage");
          });
      }
    },

    reloadPage() {
      this.dialogFormOffer = false;
      this.dialogFormCounterOffer = false;
      this.$emit("reloadPage");
    },

    async onCloseModalFormOffer() {
      if (
        await this.$root.$confirm(
          this.$t(
            "components.views.trip.offers.transport_company.card_transport_company.cancel_title"
          ),
          this.$t(
            "components.views.trip.offers.transport_company.card_transport_company.cancel_description"
          ),
          "Si, Cancelar"
        )
      ) {
        this.dialogFormOffer = false;
        this.dialogFormCounterOffer = false;
      }
    },

    timeElpased(CreatedOn) {
      let now = new Date();
      let countFrom = new Date(CreatedOn).getTime();
      let timeDifference = now - countFrom;
      let secondsInADay = 60 * 60 * 1000 * 24;
      let secondsInAHour = 60 * 60 * 1000;
      let days = Math.floor((timeDifference / secondsInADay) * 1);
      let years = Math.floor(days / 365);

      if (years > 1) {
        days = days - years * 365;
      }

      let hours = Math.floor(
        ((timeDifference % secondsInADay) / secondsInAHour) * 1
      );
      let mins = Math.floor(
        (((timeDifference % secondsInADay) % secondsInAHour) / (60 * 1000)) * 1
      );

      let mess_days =
        days > 0
          ? `${days} ${this.$t(
              "components.views.trip.offers.client.card_transport_company.days"
            )} ${this.$t(
              "components.views.trip.offers.client.card_transport_company.and"
            )}`
          : "";

      let hour =
        hours > 0
          ? `${hours} ${this.$t(
              "components.views.trip.offers.client.card_transport_company.hours"
            )}`
          : "";

      if (hour === "") {
        return `${mins} Min(s)`;
      }

      return `${mess_days} ${hour}`;
    },
    onOpenInfoOffer() {
      this.index += 1;
      this.dialogInfoOffer = true;
    },
    onCloseInfoOffer() {
      this.dialogInfoOffer = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './CardTransportCompanyStyle.scss';

.card-chip {
    height: 18px;
}


.chip-text {
  font-size: 12px;
}

 /* Pantallas  grandes (1300px o más) */
 @media (min-width: 1300px) {
      .chip-text {
        font-size: 15px;
      }
      .card-chip {
        height: 20px;
      }

    }

    /* Pantallas extra grandes (1600px o más) */
    @media (min-width: 1600px) {
      .chip-text {
        font-size: 15px;
      }
      .card-chip {
        height: 20px;
      }

    }
</style>
